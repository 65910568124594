.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;

  perspective: 100px;
  transform-style: preserve-3d;
  perspective-origin: 40% 70%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    transform-origin: 0% 100% 100%;
    animation: App-logo-spin both 1s ease;
  }
}

.App-header {
  // background-color: #282c34;
  background-color: whitesmoke;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  font-size: calc(10px + 2vmin);
  color: #282c34;
}

.App-link {
  color: rgb(145, 152, 167);
}

@keyframes App-logo-spin {
  0%{
    // transform: perspective(300px) translateY(500px) translateX(-400px) rotateX(30deg) translate3d(0px, 95px, 40px) scale3d(1.21, 2.61, 1.44) skewY(-40deg) scale(0.1);
    transform: perspective(200px) translateY(-400px) translateX(-500px) rotateX(-30deg) translate3d(0px, 100px, -40px) scale3d(1.21, 2.61, 1.44) skewY(30deg) scale(0.1);
    opacity: 0.5;
    // transform: translateY(500px) translateY(-400px) scale(0.1);
  }
  45% {
    // transform: scale(5) skewX(15deg) skewY(5deg);
    // transform: matrix3d(0.17,0,-0.98,0.001,0.00,1.1900000000000002,0.98,-0.003,0.98,-0.98,0.028900000000000006,0,0,0,90,1) scale(2);
    // transform: perspective(600px) rotateX(90deg) scale(0.5);

    transform: perspective(300px) translateX(-50px) rotateX(30deg) translate3d(0px, 95px, 40px) scale3d(1.21, 2.61, 1.44) skewY(-36deg) scale(2);
    opacity: 0.9;
  }
  90% {
    transform: scale3d(1.2, 1.2, 1.13) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale(1);
    opacity: 1;
  }
}